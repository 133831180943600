@import url("../ShopTheme.css");

.categoryDropdown .dropdown-item.active,
.categoryDropdown .category-name.active {
  color: var(--shop-theme-color);
}

.categoryDropdown .dropdown-item:hover,
.categoryDropdown .category-name:hover {
  color: var(--shop-theme-color);
  text-decoration: none;
}

.mobile-main-category {
  height: 150px;
  overflow: scroll;

}