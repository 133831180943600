.mobile-logo {
  display: none;
}
@media (max-width: 575.98px) {
  .mobile-logo {
    display: block;
  }
  .desktop-logo {
    display: none;
  }
}
