.nav-subnav {
  position: absolute;
  display: none;
  bottom: 0;
  transform: translateY(100%);
  padding: 10px;
  font-size: 14px;
  margin-top: 5px;

  font-weight: 600;
  min-width: 250px;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}
.nav-link {
  color: #333;
}

.nav-link p {
  margin-bottom: 0rem;
}

.mobilemenutriggers .nav-link span {
  color: #333;
}

.nav-item:hover .nav-subnav {
  display: flex;
}
.nav .nav-item .nav-link {
  font-weight: 400;
}

.mobile-menu-toggle {
  display: none;
}

.mobilemenutriggers {
  display: none !important;
}

.mobile-menu {
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  width: 100% !important;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 60px 30px;
  font-size: 24px !important;
  z-index: 999999;
}

.mobile-menu .nav-link,
.mobile-menu .nav-link font {
  color: #333;
}

.mobilemenu-open-template {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: 0.25s all ease-in-out;

  z-index: 99999999;
}

.mobilemenu-open-template-open {
  transform: translateX(0%);
}

.mobilemenu-open-template-closed {
  transform: translateX(100%);
}

@media screen and (max-width: 991px) {
  .navbar {
    position: unset !important;
  }

  .mobile-menu-unordered-list {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .nav-subnav .nav-link {
    font-size: 14px !important;
  }

  .mobile-menu-unordered-list .nav-item a font {
    font-size: 20px !important;
  }

  .mobile-menu-unordered-list .nav-item a {
    font-size: 20px !important;
  }

  .mobile-menu-unordered-list .nav-item div {
    font-size: 20px !important;
  }

  .mobile-menu-unordered-list .nav-item .nav-link font {
    font-size: 20px !important;
  }

  .nav-subnav {
    margin-top: 5px;
    padding: 2rem;
    gap: 15px;
  }
}
